import React from 'react'
import { BsTwitter, BsInstagram, BsGithub, BsDribbble,BsLinkedin } from 'react-icons/bs';
import { FaFacebook } from 'react-icons/fa';

function SocialMedia() {
  return (
    <div className='app__social'>
      <div>
           <a href='https://dribbble.com/aasna07'><BsDribbble /></a> 
        </div>
        <div>
           <a href='https://www.linkedin.com/in/asna-shakya-7207b2149/'><BsLinkedin /></a> 
        </div>
        <div>
            <a href='https://github.com/aasna7'><BsGithub /></a>
        </div>
        <div>
            <BsInstagram />
        </div>
        <div>
            <FaFacebook />
        </div>
    </div>
  )
}

export default SocialMedia